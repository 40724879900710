.pesaRusa{
	width: 10%;
}

.row{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

nav{
	background-color: black;
	color: white;
}

@media(min-width:701px){
	.width{
		display: none;
	}


}